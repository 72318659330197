import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "./css/Home.css";
import DogInRain from "./images/dog_in_the_rain.webp";
import CatOnBike from "./images/cat_on_bike.webp";

const HomePage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [closestDate, setClosestDate] = useState(null);
  const [shouldRender, setShouldRender] = useState(false);
  const [error, setError] = useState(null);

  // Preload images
  useEffect(() => {
    const preloadImages = async () => {
      const images = [DogInRain, CatOnBike];
      await Promise.all(
        images.map((image) => {
          const img = new Image();
          img.src = image;
          return img;
        })
      );
    };

    preloadImages();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userUUID = Cookies.get("userUUID");

      try {
        const responseUserData = await fetch(`/get_user_data/${userUUID}`);
        const dataUser = await responseUserData.json();
        setUserData(dataUser);

        const currentDate = new Date().toISOString().split("T")[0];
        const responseWeatherData = await fetch(
          `/get_weather?date=${currentDate}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const dataWeather = await responseWeatherData.json();
        // Check if the response contains an error
        if (dataWeather.error) {
          setError(
            "Your city might not be compatible. Please make sure you haven't made a typo."
          );
        } else {
          setWeatherData(dataWeather);

          const today = new Date().toISOString().split("T")[0];
          const closestDate = Object.keys(dataWeather).find(
            (date) => date >= today
          );
          setClosestDate(closestDate);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Your city might not be compatible. Please make sure you haven't made a typo.");
      } finally {
        // Set shouldRender to true after fetching data
        setShouldRender(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const hasFilledFormBefore = Cookies.get("hasFilledForm");

    if (!hasFilledFormBefore || hasFilledFormBefore === "false") {
      navigate("/settings");
    }
  }, [navigate]);

  return (
    <div
      className={`background ${shouldRender
        ? weatherData && weatherData[closestDate]
          ? "good-weather"
          : "bad-weather"
        : "white-background"
        }`}
    >
      {shouldRender ? (
        <div className="outer-container">
          <div className="container">
            <header className="header-homepage">
              <h1 >Cycling check</h1>
            </header>
            <main>
              {error ? (
                <p className="error-message">{error}</p>
              ) : (
                <>
                  {weatherData ? (
                    <>
                      {Object.keys(weatherData).map((date, index) => (
                        <div key={date}>
                          {index === 0 ? (
                            <>
                              <p className="FirstDate" key={date}>
                                {closestDate === date
                                  ? weatherData[date]
                                    ? `Good news, the weather is on your side tomorrow in ${userData.place}! You can go bike!`
                                    : `Taking the bike tomorrow isn't a good idea in ${userData.place}...`
                                  : ""}
                              </p>
                              {closestDate === date && weatherData[date] && (
                                <img
                                  className="catimage"
                                  src={CatOnBike}
                                  alt="Cat on Bike"
                                />
                              )}
                              {closestDate === date && !weatherData[date] && (
                                <img
                                  className="dogimage"
                                  src={DogInRain}
                                  alt="Dog in Rain"
                                />
                              )}
                            </>
                          ) : (
                            <div key={date}>
                              <p>
                                {userData
                                  ? weatherData[date]
                                  : "Loading weather data..."}
                              </p>
                              <div className="date-mark-container" key={date}>
                                <p className="date">{date}</p>
                                {weatherData[date] ? (
                                  <span className="checkmark">✓</span>
                                ) : (
                                  <span className="cross">✗</span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <p>Loading weather data...</p>
                  )}
                </>
              )}
              <Link to="/settings">
                <button className="settings-button-home">
                  <span role="img" aria-label="Settings">
                    Settings
                  </span>
                </button>
              </Link>
              <Link to="/contact">
                <button className="contact-button">
                  <span role="img" aria-label="Contact">
                    Contact me
                  </span>
                </button>
              </Link>
            </main>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
