import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "./css/Settings.css";

import Time from "./settings/Time";
import Place from "./settings/Place";
import MinimumTemp from "./settings/MinimumTemp";
import MaximumTemp from "./settings/MaximumTemp";
import MaximumPrecipitation from "./settings/MaximumPrecipitation";
import MaximumWind from "./settings/MaximumWind";

import SaveButton from "./settings/SaveButton";

const SettingsPageComponent = () => {
  const navigate = useNavigate();

  const [userUUID, setUserUUID] = useState(null);
  const [time, setTime] = useState("");
  const [place, setPlace] = useState("");
  const [minTemp, setMinTemp] = useState("10");
  const [maxTemp, setMaxTemp] = useState("30");
  const [maxPrecipitation, setMaxPrecipitation] = useState("25");
  const [maxWindForce, setMaxWindForce] = useState(3);
  const [hasFilledForm, setHasFilledForm] = useState(false);

  const [loading, setLoading] = useState(false);

  // Function to check if all required fields are filled and have valid values
  const areAllFieldsFilledAndValid = () => {
    const isMinTempValid = !isNaN(parseFloat(minTemp));
    const isMaxTempValid = !isNaN(parseFloat(maxTemp));
    const isMaxPrecipitationValid = !isNaN(parseFloat(maxPrecipitation));
    const isMaxWindForceValid = !isNaN(parseFloat(maxWindForce));

    return (
      time.toString().trim() !== "" &&
      place.toString().trim() !== "" &&
      minTemp.toString().trim() !== "" &&
      maxTemp.toString().trim() !== "" &&
      maxPrecipitation.toString().trim() !== "" &&
      isMinTempValid &&
      isMaxTempValid &&
      isMaxPrecipitationValid &&
      isMaxWindForceValid
    );
  };

  useEffect(() => {
    // Check if user has filled the form before using cookies
    const hasFilledFormBefore = Cookies.get("hasFilledForm");
    const storedUserUUID = Cookies.get("userUUID");

    if (hasFilledFormBefore && storedUserUUID) {
      // User has filled the form before, retrieve data from the backend using the user UUID
      setUserUUID(storedUserUUID);
      setHasFilledForm(true);

      // Make a GET request to the Flask endpoint with the correct route and user UUID
      fetch("/get_user_data/" + storedUserUUID,)
        .then((response) => response.json())
        .then((result) => {
          // console.log("Fetched data:", result);
          // Update local state with retrieved data
          setTime(result.time || "");
          setPlace(result.place || "");
          setMinTemp(result.minTemp === 0 ? "0" : result.minTemp || "10");
          setMaxTemp(result.maxTemp === 0 ? "0" : result.maxTemp || "30");
          setMaxPrecipitation(
            result.maxPrecipitation === 0
              ? "0"
              : result.maxPrecipitation || "25"
          );
          setMaxWindForce(
            result.maxWindForce === 0 ? "0" : result.maxWindForce || 12
          );
        })
        .catch((error) => {
          console.error("Error retrieving data:", error);
        });
    }
  }, []);

  const handleSave = () => {
    if (areAllFieldsFilledAndValid()) {
      // Convert minTemp and maxTemp to numbers for numerical comparison
      const minTempValue = parseFloat(minTemp);
      const maxTempValue = parseFloat(maxTemp);

      // Check if maxTemp is greater than or equal to minTemp
      if (maxTempValue < minTempValue) {
        window.alert(
          "Maximum temperature can not be below the minimum temperature."
        );
        return; // Stop further execution if validation fails
      }

      const data = {
        time,
        place,
        minTemp,
        maxTemp,
        maxPrecipitation,
        maxWindForce,
      };

      setLoading(true);

      // If user has a UUID, update the existing data in the database
      if (userUUID) {
        fetch("/update_data/" + userUUID, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            // console.log(result.message);
            setLoading(false);
            navigate("/");
          })
          .catch((error) => {
            console.error("Error updating data:", error);
            setLoading(false);
            window.alert("Error updating data. Please try again.");
          });
      } else {
        // User doesn't have a UUID, create a new record in the database
        fetch("/save_data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((result) => {
            // console.log(result.message);
            const backendUserId = result.user_id;

            if (backendUserId) {
              Cookies.set("userUUID", backendUserId);
              setUserUUID(backendUserId);
            } else {
              console.warn("No user_id returned from the backend.");
            }

            Cookies.set("hasFilledForm", "true");
            setLoading(false);
            navigate("/");
          })
          .catch((error) => {
            console.error("Error saving data:", error);
            setLoading(false);
            window.alert("Error saving data. Please try again.");
          });
      }
    } else {
      window.alert("Make sure all fields are filled in!");
    }
  };

  const buttonColor =
    areAllFieldsFilledAndValid() && parseFloat(maxTemp) >= parseFloat(minTemp)
      ? "green"
      : "red";

  return (
    <div className="settings-page">
      {hasFilledForm ? (
        <>
          <h1>Settings</h1>
          <p>Your previous data has been loaded, you can now adjust it!</p>
        </>
      ) : (
        <>
          <h1>Settings</h1>
          <p>
            Enter the information below, so we can check if it is cycling
            weather for you.
          </p>
        </>
      )}

      <Time time={time} setTime={setTime} />
      <Place place={place} setPlace={setPlace} />
      <MinimumTemp minTemp={minTemp} setMinTemp={setMinTemp} />
      <MaximumTemp maxTemp={maxTemp} setMaxTemp={setMaxTemp} />
      <MaximumPrecipitation
        maximumPrecipitation={maxPrecipitation}
        setMaximumPrecipitation={setMaxPrecipitation}
      />
      <MaximumWind
        maxWindForce={maxWindForce}
        setMaxWindForce={setMaxWindForce}
      />
      <SaveButton onSave={handleSave} color={buttonColor} />

      {loading && <p>Loading...</p>}
    </div>
  );
};

export default SettingsPageComponent;
