// src/components/MinimumTemp.js
import React, { useState, useEffect } from "react";

const MinimumTemp = ({ minTemp, setMinTemp }) => {
  const initialMinTempValue = 10;
  const [initialMinTemp, setInitialMinTemp] = useState(initialMinTempValue);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // Set the initial min temperature when the component mounts
    if (minTemp !== null && minTemp !== undefined) {
      setInitialMinTemp(minTemp);
    } else {
      // If minTemp is not available, use the default value (10 or the user's data)
      setInitialMinTemp(initialMinTempValue);
    }
  }, [minTemp, initialMinTempValue]);

  const handleMinTempChange = (e) => {
    // Ensure the input value stays within a certain range
    const newValue = Math.min(Math.max(parseInt(e.target.value, 10), 0), 100);

    // Update both initialMinTemp (for initial value) and minTemp (for updated value)
    setInitialMinTemp(newValue);
    setMinTemp(newValue);
    touched && setTouched(true);
  };

  return (
    <div>
      <label>Minimum temperature in degrees: </label>
      <input
        type="number"
        value={initialMinTemp}
        onChange={handleMinTempChange}
        onBlur={() => setTouched(true)} // Considered touched when blurred
      />
      {touched && !minTemp && initialMinTemp !== 0 && (
        <p className="error-message">Minimum temperature is required.</p>
      )}
    </div>
  );
};

export default MinimumTemp;
