// src/components/MaximumPrecipitation.js
import React, { useState, useEffect } from "react";

const MaximumPrecipitation = ({
  maximumPrecipitation,
  setMaximumPrecipitation,
}) => {
  const initialMaximumPrecipitationValue = 20;
  const [initialMaximumPrecipitation, setInitialMaximumPrecipitation] =
    useState(initialMaximumPrecipitationValue);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // Set the initial max Precipitation chance when the component mounts
    if (maximumPrecipitation !== null && maximumPrecipitation !== undefined) {
      setInitialMaximumPrecipitation(maximumPrecipitation);
    } else {
      // If it's not available, use the default value
      setInitialMaximumPrecipitation(initialMaximumPrecipitationValue);
    }
  }, [maximumPrecipitation, initialMaximumPrecipitationValue]);

  const handleInputChange = (e) => {
    // Ensure the input value stays between 0 and 100
    const newValue = Math.min(Math.max(parseInt(e.target.value, 10), 0), 100);

    // Update both initialMaximumPrecipitation (for initial value) and maximumPrecipitation (for updated value)
    setInitialMaximumPrecipitation(newValue);
    setMaximumPrecipitation(newValue);
    touched && setTouched(true);
  };

  return (
    <div>
      <label>Maximum chance for rain: </label>
      <input
        type="number"
        value={initialMaximumPrecipitation}
        onChange={handleInputChange}
        onBlur={() => setTouched(true)} // Considered touched when blurred
      />
      {touched &&
        !maximumPrecipitation &&
        initialMaximumPrecipitation !== 0 && (
          <p className="error-message">Maximum chance for rain is required.</p>
        )}
    </div>
  );
};

export default MaximumPrecipitation;
