// src/components/MaximumTemp.js
import React, { useState, useEffect } from "react";

const MaximumTemp = ({ maxTemp, setMaxTemp }) => {
  const initialMaxTempValue = 28;
  const [initialMaxTemp, setInitialMaxTemp] = useState(initialMaxTempValue);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // Set the initial max temperature when the component mounts
    if (maxTemp !== null && maxTemp !== undefined) {
      setInitialMaxTemp(maxTemp);
    } else {
      // If maxTemp is not available, use the default value (28 or the user's data)
      setInitialMaxTemp(initialMaxTempValue);
    }
  }, [maxTemp, initialMaxTempValue]);

  const handleMaxTempChange = (e) => {
    // Ensure the input value stays within a certain range
    const newValue = Math.min(Math.max(parseInt(e.target.value, 10), 0), 100);

    // Update both initialMaxTemp (for initial value) and maxTemp (for updated value)
    setInitialMaxTemp(newValue);
    setMaxTemp(newValue);
    touched && setTouched(true);
  };

  return (
    <div>
      <label>Maximum temperature in degrees: </label>
      <input
        type="number"
        value={initialMaxTemp}
        onChange={handleMaxTempChange}
        onBlur={() => setTouched(true)} // Considered touched when blurred
      />
      {touched && !maxTemp && initialMaxTemp !== 0 && (
        <p className="error-message">Maximum temperature is required.</p>
      )}
    </div>
  );
};

export default MaximumTemp;
