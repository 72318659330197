// SaveButton.js
import React from "react";

const SaveButton = ({ onSave, color }) => {
  return (
    <button
      className="settings-button"
      onClick={onSave}
      style={{
        backgroundColor: color,
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Save
    </button>
  );
};

export default SaveButton;
