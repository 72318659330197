import React, { useState, useEffect } from "react";

const MaximumWind = ({ maxWindForce, setMaxWindForce }) => {
  const initialMaxWindValue = 3; // Set the initial max wind in m/s value to 3
  const [initialMaxWind, setInitialMaxWind] = useState(initialMaxWindValue);
  const [touched, setTouched] = useState(false);
  const [infoBoxVisible, setInfoBoxVisible] = useState(false);

  useEffect(() => {
    // Set the initial max wind force when the component mounts
    if (maxWindForce !== null && maxWindForce !== undefined) {
      setInitialMaxWind(maxWindForce);
    } else {
      // If maxWindForce is not available, use the default value (3 or the user's data)
      setInitialMaxWind(initialMaxWindValue);
    }
  }, [maxWindForce, initialMaxWindValue]);

  const handleInputChange = (e) => {
    // Ensure the input value stays within a certain range
    const newValue = Math.min(Math.max(parseInt(e.target.value, 10), 0), 33);

    // Update both initialMaxWind (for initial value) and maxWindForce (for updated value)
    setInitialMaxWind(newValue);
    setMaxWindForce(newValue);
    touched && setTouched(true);
  };

  const closeInfoBox = () => {
    setInfoBoxVisible(false);
  };

  return (
    <div className="wind-input-container">
      <label>
        Maximum wind in m/s:
        <span
          className="info-icon"
          title="Click for more information"
          onClick={() => setInfoBoxVisible(!infoBoxVisible)}
        >
          Info
        </span>
      </label>
      <input
        type="number"
        value={initialMaxWind}
        onChange={handleInputChange}
        onBlur={() => setTouched(true)}
      />
      {touched && !maxWindForce && initialMaxWind !== 0 && (
        <p className="error-message">Maximum wind in m/s is required.</p>
      )}
      {infoBoxVisible && (
        <div className="info-popup">
          Wind force → m/s
          <p> 0 → 0 - 0,2</p>
          <p> 1 → 0,3 - 1,5 </p>
          <p> 2 → 1,6 - 3,3</p>
          <p> 3 → 3,4 - 5,4</p>
          <p> 4 → 5,5 - 7,9</p>
          <p> 5 → 8,0 - 10,7</p>
          <p> 6 → 10,8 - 13,8</p>
          <p> 7 → 13,9 - 17,1</p>
          <p> 8 → 17,2 - 20,7</p>
          <p> 9 → 20,8 - 24,4</p>
          <p> 10 → 24,5 - 28,4</p>
          <p> 11 → 28,5 - 32,6</p>
          <p> 12 → &gt; 32,6</p>
          <span className="close" onClick={closeInfoBox}>Close</span>
        </div>
      )}
    </div>
  );
};

export default MaximumWind;
