// src/components/Time.js
import React, { useEffect, useState } from "react";

const Time = ({ time, setTime }) => {
  const initialTime = "08:00"; // Set the initial time
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setTime(initialTime);
  }, [setTime]);

  const handleTimeChange = (e) => {
    setTouched(true);
    setTime(e.target.value);
  };

  return (
    <div>
      <label>Time of departure: </label>
      <input
        type="time"
        value={time}
        onChange={handleTimeChange}
        onBlur={() => setTouched(true)} // Considered touched when blurred
      />
      {touched && !time && (
        <p className="error-message">Time of departure is required.</p>
      )}
    </div>
  );
};

export default Time;
