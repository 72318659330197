import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./css/Contact.css";

const ContactPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;
    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: new FormData(form),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        alert("Message sent successfully!"); // Display pop-up message
        form.reset(); // Reset the form fields
        window.location.href = "/";
      } else {
        alert("Message failed to send. Please try again."); // Display error message
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasFilledFormBefore = Cookies.get("hasFilledForm");

    if (!hasFilledFormBefore || hasFilledFormBefore === "false") {
      navigate("/settings");
    }
  }, [navigate]);

  return (
    <div className="contact-page">
      <form
        onSubmit={handleSubmit}
        action="https://formspree.io/f/mrgndzpk"
        method="POST"
      >
        <div className="contact-page-background"></div>
        <h1>Contact me</h1>
        <h3>If you want to contact me, you can do so here!</h3>
        <label>
          Your email:
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            required
          />
        </label>
        <label>
          Your message:
          <textarea
            name="message"
            placeholder="Write your message here"
            required
          ></textarea>
        </label>
        <div className="button-wrapper">
          <button type="submit" disabled={loading}>
            {loading ? "Sending..." : "Send message"}
          </button>
        </div>
      </form>
      <Link to="/">
        <button className="save-button" style={{ backgroundColor: "#007bff" }}>
          Go back
        </button>
      </Link>
    </div>
  );
};

export default ContactPage;
