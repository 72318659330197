import React, { useState, useEffect } from "react";

const Place = ({ place, setPlace }) => {
  const [initialPlace, setInitialPlace] = useState("");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // Set the initial place when the component mounts
    if (place !== null && place !== undefined) {
      setInitialPlace(place);
    } else {
      // If place is not available, use an empty string or the user's data
      setInitialPlace("");
    }
  }, [place]);

  const handlePlaceChange = (e) => {
    // Capitalize the first letter of the input value
    const trimmedValue = e.target.value.trim();
    const capitalizedPlace = trimmedValue.charAt(0).toUpperCase() + trimmedValue.slice(1);


    // Update both initialPlace (for initial value) and place (for updated value)
    setTouched(true);
    setInitialPlace(capitalizedPlace);
    setPlace(capitalizedPlace);
  };

  return (
    <div>
      <label>City: </label>
      <input
        type="text"
        value={initialPlace}
        onChange={handlePlaceChange}
        onBlur={() => setTouched(true)} // Considered touched when blurred
      />
      {touched && !place && <p className="error-message">City is required.</p>}
    </div>
  );
};

export default Place;
